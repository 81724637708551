import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from 'lodash';
import AdmTooltip from '../shared/adm_tooltip';
import AdmDescription from '../shared/adm_description';
import RubricToggle from '../shared/rubric_toggle/index';
import Editor from '../../shared/rte/editor';
import { Box } from '@mui/material';
import TextTruncator from '../../shared/text_truncator';

const isHqimInUseDefined = (hqim_in_use) => {
  return !!hqim_in_use || hqim_in_use === false;
};

const hideBasedOnConditionality = (hqim_in_use, config) => {
  if (!isHqimInUseDefined(hqim_in_use)) {
    return false;
  }
  if (config?.hqim_conditionality === 'hide_if_hqim_yes') {
    return hqim_in_use === true;
  }
  if (config?.hqim_conditionality === 'hide_if_hqim_no') {
    return hqim_in_use === false;
  }
  return false;
};

const isDisabledIndicatorQuestion = (indicator, contentAreaId) => {
  return contentAreaId === null && indicator.content_area_id !== null;
};

const isDisabledNode = (node, response, hqim_in_use) => {
  const config = node.survey_config;
  if (config?.hqim_conditionality && !isHqimInUseDefined(hqim_in_use)) {
    return true;
  }
  if (config?.hqim_conditionality === 'yes_and_lock_if_hqim_yes') {
    return hqim_in_use === true;
  }
  return false;
};

const NotesInput = ({ observation, config, createResponse, admNode, disabled = false, readOnly = false, dialogRef, enableRichTextEditors }) => {
  const response = observation?.responses?.find((response) => response.adm_node_type === admNode.type && response.adm_node_id === admNode.id);

  if (enableRichTextEditors) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const initialContentRef = useRef(response?.notes);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleSave = useCallback(
      (notes) => {
        createResponse(admNode, null, notes);
      },
      [createResponse, admNode]
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      initialContentRef.current = response?.notes;
    }, []);

    return (
      <Box className="mt-3 mb-3">
        <Editor
          minHeight={'40px'}
          initialContent={initialContentRef.current}
          placeholder={admNode.name + ' Notes...'}
          containerRef={dialogRef}
          onContentChange={handleSave}
          disabled={disabled}
          hideMenuBarIfDisabled={true}
        />
      </Box>
    );
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const debouncedHandleSave = useCallback(
      debounce((admNode, value, notes) => {
        createResponse(admNode, value, notes);
      }, 500),
      [createResponse]
    );

    return (
      <TextField
        className="w-100 mt-3 mb-3"
        label={admNode.name + ' Notes'}
        onChange={(e) => debouncedHandleSave(admNode, null, e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={response?.notes}
        multiline
        minRows={3}
        disabled={disabled}
      />
    );
  }
};

const QuestionInput = ({ observation, config, createResponse, admNode, disabled = false, response }) => {
  const debouncedHandleSave = useCallback(
    debounce((admNode, value, notes) => {
      createResponse(admNode, value, notes);
    }, 500),
    [createResponse]
  );

  if (config.question_type.includes('_point_scale')) {
    const numPoints = parseInt(config.question_type.replace('_point_scale', ''));
    return (
      <div style={{ width: 330, flexShrink: 0 }}>
        <PointScaleInput
          numPoints={numPoints}
          observation={observation}
          config={config}
          createResponse={createResponse}
          admNode={admNode}
          value={response?.value}
          disabled={disabled}
        />
      </div>
    );
  } else if (config.question_type == '0_to_1') {
    return (
      <div style={{ width: 270, flexShrink: 0 }}>
        <PointScaleInput
          points={[0, 0.5, 1]}
          observation={observation}
          config={config}
          createResponse={createResponse}
          admNode={admNode}
          value={response?.value}
          disabled={disabled}
        />
      </div>
    );
  } else if (config.question_type == '0_to_3') {
    return (
      <div style={{ width: 310, flexShrink: 0 }}>
        <PointScaleInput
          points={[0, 1, 2, 3]}
          observation={observation}
          config={config}
          createResponse={createResponse}
          admNode={admNode}
          value={response?.value}
          disabled={disabled}
        />
      </div>
    );
  } else {
    switch (config.question_type) {
      case 'observed_not_observed':
        return (
          <div style={{ width: '306px', flexShrink: 0 }}>
            <ObservedNotObservedInput
              config={config}
              createResponse={createResponse}
              admNode={admNode}
              observation={observation}
              value={response?.value}
              disabled={disabled}
            />
          </div>
        );
      case 'yes_no_na':
        return (
          <div style={{ width: '215px', flexShrink: 0 }}>
            <YesNoNaInput
              config={config}
              createResponse={createResponse}
              admNode={admNode}
              observation={observation}
              value={response?.value}
              disabled={disabled}
            />
          </div>
        );
      case 'number':
        return (
          <div style={{ width: '215px', flexShrink: 0 }}>
            <TextField
              id="outlined-number"
              label="Number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => debouncedHandleSave(admNode, e.target.value)}
              defaultValue={response?.value}
              disabled={disabled}
            />
          </div>
        );
      case 'percentage':
        return (
          <div style={{ width: '215px', flexShrink: 0 }}>
            <TextField
              id="outlined-number"
              label="Percentage"
              type="number"
              onChange={(e) => debouncedHandleSave(admNode, e.target.value)}
              defaultValue={response?.value}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
            />
          </div>
        );
      case 'text':
        return (
          <div style={{ width: '215px', flexShrink: 0 }}>
            <TextField
              id="outlined-number"
              className="w-100 mt-3 mb-3"
              label={admNode.name + ' Notes'}
              onChange={(e) => debouncedHandleSave(admNode, e.target.value)}
              defaultValue={response?.value}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
            />
          </div>
        );
      default:
        return null;
    }
  }
};

const YesNoNaInput = ({ observation, config, createResponse, admNode, value, disabled }) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={value || ''}
        name="radio-buttons-group"
        sx={{ flexWrap: 'nowrap', justifyContent: 'end' }}
        onChange={(e) => createResponse(admNode, e.target.value)}
      >
        <FormControlLabel value="yes" disabled={disabled} control={<Radio />} label="Yes" />
        <FormControlLabel value="no" disabled={disabled} control={<Radio />} label="No" />
        {config.allow_na && <FormControlLabel value="na" disabled={disabled} control={<Radio />} label="N/A" />}
      </RadioGroup>
    </FormControl>
  );
};

const ObservedNotObservedInput = ({ observation, config, createResponse, admNode, value, disabled }) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={value || ''}
        name="radio-buttons-group"
        sx={{ flexWrap: 'nowrap' }}
        onChange={(e) => createResponse(admNode, e.target.value)}
      >
        <FormControlLabel value="yes" disabled={disabled} control={<Radio />} label="Yes" />
        <FormControlLabel value="no" disabled={disabled} control={<Radio />} label="No (developing)" />
        {config.allow_na && <FormControlLabel value="na" disabled={disabled} control={<Radio />} label="N/A" labelPlacement="end" />}
      </RadioGroup>
    </FormControl>
  );
};

const PointScaleInput = ({ numPoints, points, includeNA, observation, config, createResponse, admNode, value, disabled }) => {
  const values = points ? points : Array.from({ length: numPoints }, (_, i) => i + 1).reverse();
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={value || ''}
        name="radio-buttons-group"
        sx={{ flexWrap: 'nowrap' }}
        onChange={(e) => {
          createResponse(admNode, e.target.value);
        }}
      >
        {values.map((val) => (
          <FormControlLabel key={val} value={val} disabled={disabled} control={<Radio />} label={val} labelPlacement="start" />
        ))}
        {config.allow_na && <FormControlLabel value="na" disabled={disabled} control={<Radio />} label="N/A" labelPlacement="start" />}
      </RadioGroup>
    </FormControl>
  );
};

const Indicator = ({ indicator, observation, createResponse, contentAreaId, readOnly, showAdmNumbers }) => {
  if (hideBasedOnConditionality(observation?.hqim_in_use, indicator.survey_config)) {
    return null;
  }

  const response = observation?.responses?.find((response) => response.adm_node_type === 'Care::Indicator' && response.adm_node_id === indicator.id);

  return (
    <div className={`indicator py-2`}>
      <div className="d-flex justify-content-between align-items-start gap-2 w-100">
        <div className="d-flex align-items-start">
          {showAdmNumbers && <strong className="me-3">{indicator.number}</strong>}
          <div>
            {indicator.description}
            <AdmTooltip config={indicator.survey_config} />
          </div>
        </div>
        <div style={{ width: '215px' }}>
          <QuestionInput
            config={indicator.survey_config}
            observation={observation}
            createResponse={createResponse}
            admNode={{ type: 'Care::Indicator', id: indicator.id }}
            response={response}
            disabled={
              isDisabledIndicatorQuestion(indicator, contentAreaId) || isDisabledNode(indicator, response, observation?.hqim_in_use) || readOnly
            }
          />
        </div>
      </div>
    </div>
  );
};

const SummaryIndicator = ({
  summaryIndicator,
  createResponse,
  observation,
  contentAreaId,
  readOnly,
  dialogRef,
  enableRichTextEditors,
  showAdmNumbers,
}) => {
  const nodeConfig = summaryIndicator.survey_config;
  if (hideBasedOnConditionality(observation?.hqim_in_use, nodeConfig)) {
    return null;
  }

  const response = observation?.responses?.find(
    (response) => response.adm_node_type === 'Care::SummaryIndicator' && response.adm_node_id === summaryIndicator.id
  );

  const shouldShowRubric = summaryIndicator.rubric && summaryIndicator.rubric !== '<p></p>';

  return (
    <div className={`summary-indicator px-4 py-3`} style={{ backgroundColor: '#F5F8FA' }}>
      <div className="d-flex justify-content-between align-items-center w-100 gap-2">
        <div>
          <div className="d-flex">
            <h5 className="headline-color">
              <strong>
                {showAdmNumbers && summaryIndicator.number && <span>{summaryIndicator.number}&nbsp;</span>}
                {summaryIndicator.name}
              </strong>
            </h5>
            <AdmTooltip config={nodeConfig} />
          </div>

          {!shouldShowRubric && <AdmDescription description={summaryIndicator.description} />}
        </div>
        <QuestionInput
          config={nodeConfig}
          observation={observation}
          admNode={{ type: 'Care::SummaryIndicator', id: summaryIndicator.id }}
          createResponse={createResponse}
          response={response}
          disabled={isDisabledNode(summaryIndicator, response, observation?.hqim_in_use) || readOnly}
        />
      </div>

      {shouldShowRubric && <RubricToggle rubricHtml={summaryIndicator.rubric} />}

      {summaryIndicator.survey_config.allow_notes && (
        <NotesInput
          config={nodeConfig}
          observation={observation}
          createResponse={createResponse}
          admNode={{ type: 'Care::SummaryIndicator', id: summaryIndicator.id, name: summaryIndicator.name }}
          disabled={(nodeConfig?.hqim_conditionality && !isHqimInUseDefined(observation?.hqim_in_use)) || readOnly}
          dialogRef={dialogRef}
          enableRichTextEditors={enableRichTextEditors}
        />
      )}

      <div className="pt-2">
        {summaryIndicator.indicators
          .filter((i) => {
            return contentAreaId === null || i.content_area_id === null || i.content_area_id === contentAreaId;
          })
          .map((indicator) => (
            <Indicator
              key={indicator.id}
              indicator={indicator}
              observation={observation}
              createResponse={createResponse}
              contentAreaId={contentAreaId}
              readOnly={readOnly}
              showAdmNumbers={showAdmNumbers}
            />
          ))}
      </div>
    </div>
  );
};

const Aspect = ({ aspect, observation, createResponse, contentAreaId, readOnly, showAdmNumbers, dialogRef, enableRichTextEditors }) => {
  const nodeConfig = aspect.survey_config;
  if (hideBasedOnConditionality(observation?.hqim_in_use, nodeConfig)) {
    return null;
  }

  const response = observation?.responses?.find((response) => response.adm_node_type === 'Care::Aspect' && response.adm_node_id === aspect.id);

  return (
    <div className={`mt-3 aspect`}>
      <div className="d-flex w-100 justify-content-between align-items-center pe-4">
        <div className="d-flex align-items-center gap-1">
          <h3 className="headline-color">
            {showAdmNumbers && aspect.number && <span>{aspect.number}&nbsp;</span>}
            {aspect.name}
          </h3>
          <AdmTooltip config={nodeConfig} />
        </div>
        <QuestionInput
          config={nodeConfig}
          observation={observation}
          createResponse={createResponse}
          admNode={{ type: 'Care::Aspect', id: aspect.id, name: aspect.name }}
          response={response}
          disabled={isDisabledNode(aspect, response, observation?.hqim_in_use) || readOnly}
        />
      </div>

      {aspect.rubric && aspect.rubric !== '<p></p>' ? (
        <RubricToggle rubricHtml={aspect.rubric} />
      ) : (
        <div style={{ fontSize: 16 }} className="w-50">
          <TextTruncator text={aspect.description} />
        </div>
      )}

      {aspect.survey_config.allow_notes && (
        <NotesInput
          config={nodeConfig}
          observation={observation}
          createResponse={createResponse}
          admNode={{ type: 'Care::Aspect', id: aspect.id, name: aspect.name }}
          disabled={(nodeConfig?.hqim_conditionality && !isHqimInUseDefined(observation?.hqim_in_use)) || readOnly}
          dialogRef={dialogRef}
          enableRichTextEditors={enableRichTextEditors}
        />
      )}

      <div className="pt-2">
        {aspect.summary_indicators.map((summaryIndicator) => (
          <SummaryIndicator
            key={summaryIndicator.id}
            summaryIndicator={summaryIndicator}
            observation={observation}
            createResponse={createResponse}
            contentAreaId={contentAreaId}
            readOnly={readOnly}
            showAdmNumbers={showAdmNumbers}
            dialogRef={dialogRef}
            enableRichTextEditors={enableRichTextEditors}
          />
        ))}
      </div>
    </div>
  );
};

const AspectCategory = ({
  aspectCategory,
  observation,
  createResponse,
  contentAreaId,
  readOnly,
  showAdmNumbers,
  dialogRef,
  enableRichTextEditors,
}) => {
  return (
    <div className="mt-5" key={aspectCategory.id}>
      <h1 className="headline-color">{aspectCategory.name}</h1>
      <div>{aspectCategory.description}</div>
      {aspectCategory.survey_config.allow_notes && (
        <NotesInput
          label={aspectCategory.name + ' Notes'}
          config={aspectCategory.survey_config}
          observation={observation}
          admNode={{
            type: 'Care::AspectCategory',
            id: aspectCategory.id,
            name: aspectCategory.name,
          }}
          createResponse={createResponse}
          disabled={readOnly}
          dialogRef={dialogRef}
          enableRichTextEditors={enableRichTextEditors}
        />
      )}
      <div className="mt-3">
        {aspectCategory.aspects.map((aspect) => (
          <Aspect
            key={aspect.id}
            aspect={aspect}
            observation={observation}
            createResponse={createResponse}
            contentAreaId={contentAreaId}
            readOnly={readOnly}
            showAdmNumbers={showAdmNumbers}
            dialogRef={dialogRef}
            enableRichTextEditors={enableRichTextEditors}
          />
        ))}
      </div>
    </div>
  );
};

export default ({ ...props }) => {
  return (
    <div className="mt-3 pb-5 adm-selector">
      {props.admTree.map(
        (aspectCategory) =>
          (aspectCategory?.aspects?.length > 0 || (aspectCategory.survey_config.allow_notes && aspectCategory.survey_config.required)) && (
            <AspectCategory
              key={aspectCategory.id}
              aspectCategory={aspectCategory}
              observation={props.observation}
              createResponse={props.createResponse}
              contentAreaId={props.contentAreaId}
              readOnly={props.readOnly}
              showAdmNumbers={props.showAdmNumbers}
              dialogRef={props.dialogRef}
              enableRichTextEditors={props.enableRichTextEditors}
            />
          )
      )}
    </div>
  );
};

const surveyConfigShape = PropTypes.shape({
  question_type: PropTypes.string,
  allow_notes: PropTypes.bool,
  tooltip_text: PropTypes.string,
  hqim_conditionality: PropTypes.string,
});

AspectCategory.propTypes = {
  aspectCategory: PropTypes.object,
  observation: PropTypes.object,
  createResponse: PropTypes.func,
  survey_config: surveyConfigShape,
};

Aspect.propTypes = {
  aspect: PropTypes.object,
  observation: PropTypes.object,
  createResponse: PropTypes.func,
  survey_config: surveyConfigShape,
  readOnly: PropTypes.bool,
};

SummaryIndicator.propTypes = {
  summaryIndicator: PropTypes.object,
  observation: PropTypes.object,
  createResponse: PropTypes.func,
  survey_config: surveyConfigShape,
  readOnly: PropTypes.bool,
};

Indicator.propTypes = {
  indicator: PropTypes.object,
  observation: PropTypes.object,
  createResponse: PropTypes.func,
  survey_config: surveyConfigShape,
  readOnly: PropTypes.bool,
};
