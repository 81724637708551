/**
 * Shared utility functions for CARE Tool
 */

export const grades = ['PK', 'K', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th'];
export const diversityOptions = ['Yes', 'No', 'Unclear'];

// Observers are not included in the survey object
export const extractObservers = (observations = []) => {
  return [...new Map(observations.map((o) => [o.observer.id, o.observer])).values()];
};

// Grade levels are stored as strings
export const extractGradeLevels = (observations = []) => {
  return [...new Map(observations.map((o) => [o.grade_level, o.grade_level])).values()]
    .filter((n) => grades.includes(n))
    .sort((a, b) => grades.indexOf(a) - grades.indexOf(b));
};

export const extractDiversityOptions = (observations = []) => {
  return [...new Map(observations.map((o) => [o.diverse_classroom, o.diverse_classroom])).values()]
    .filter((n) => diversityOptions.includes(n))
    .sort((a, b) => diversityOptions.indexOf(a) - diversityOptions.indexOf(b));
};

// For property objects having an id, extract the unique objects and sort them
export const extractUniqueAndSort = (surveys = [], observations = [], surveyProp, observerProp) => {
  const surveyPropIds = surveys.map((survey) => survey[surveyProp].map((item) => item.id)).flat();
  const sortedIds = [...new Set(surveyPropIds)];

  return [
    ...new Map(
      observations.filter((o) => o[observerProp] !== null && o[observerProp] !== undefined).map((o) => [o[observerProp].id, o[observerProp]])
    ).values(),
  ].sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));
};

export const isPlcModeEnabled = (survey) => survey['plc_mode?'];
export const isHqimAnalysisIncluded = (survey) => survey['includes_hqim_analysis?'];
export const isRacialDiversityAnalysisIncluded = (survey) => survey['includes_racial_diversity_analysis?'];
export const isClassGroupingAnalysisEnabled = (survey) => survey['class_grouping_analysis?'];
export const isScriptingNotesEnabled = (survey) => survey['enable_scripting_notes?'];
export const isLangOfInstructionIncluded = (survey) => survey['includes_language_of_instruction?'];
export const isShareObservationOnCompleteEnabled = (survey) => survey['enable_send_email_dialog_on_observation_complete'];
export const isReportDisplaySummaryOnly = (survey) => survey['report_display_summary_only?'];
export const isShowAdmNumbers = (survey) => survey['show_adm_numbers?'];

// Per #3467, hiding student engagement data (chart and filters)
export const hideClassGroupingData = (survey) => {
  return isPlcModeEnabled(survey) || !isClassGroupingAnalysisEnabled(survey);
};

export const isPointScaleQuestion = (node) => {
  return node.survey_config.question_type.includes('_point_scale') || node.survey_config.question_type.includes('_to_');
};

export const getNumPoints = (node) => {
  if (!isPointScaleQuestion(node)) {
    return null;
  } else {
    if (node.survey_config.question_type.includes('_point_scale')) {
      return parseInt(node.survey_config.question_type.replace('_point_scale', ''));
    } else {
      return parseInt(node.survey_config.question_type.split('_to_')[1]);
    }
  }
};

export const isShowContentFilter = (surveys = [], observations = []) => {
  let contentAreas = extractUniqueAndSort(surveys, observations, 'content_areas', 'content_area');
  let showContent = surveys.every((survey) => isHqimAnalysisIncluded(survey) || isReportDisplaySummaryOnly(survey));
  return contentAreas.length > 0 && showContent;
};

export const isShowDiversityFilter = (surveys = [], observations = []) => {
  let diversityOptions = extractDiversityOptions(observations);
  let showDiversity = surveys.every(isRacialDiversityAnalysisIncluded);
  return diversityOptions.length > 0 && showDiversity;
};

export const isShowSchoolNameFilter = (surveys = [], observations = []) => {
  let schoolNames = extractUniqueAndSort(surveys, observations, 'school_names', 'school_name');
  return schoolNames.length > 0;
};

export const isShowGradeLevelsFilter = (surveys = [], observations = []) => {
  let gradeLevels = extractGradeLevels(observations);
  return gradeLevels.length > 0;
};

export const isShowObservablesFilter = (surveys = [], observations = []) => {
  let observables = extractUniqueAndSort(surveys, observations, 'observables', 'observable');
  return observables.length > 0;
};

export const isShowInstructionLanguagesFilter = (surveys = [], observations = []) => {
  let instructionLanguages = extractUniqueAndSort(surveys, observations, 'instruction_languages', 'instruction_language');
  return instructionLanguages.length > 0;
};

export const isShowObserversFilter = (surveys = [], observations = []) => {
  let observers = extractObservers(observations);
  return observers.length > 0;
};

export const getFiltersMap = (surveys = [], observations = []) => {
  return {
    showContentFilter: isShowContentFilter(surveys, observations),
    showDiversityFilter: isShowDiversityFilter(surveys, observations),
    showSchoolNameFilter: isShowSchoolNameFilter(surveys, observations),
    showGradeLevelsFilter: isShowGradeLevelsFilter(surveys, observations),
    showObservablesFilter: isShowObservablesFilter(surveys, observations),
    showInstructionLanguagesFilter: isShowInstructionLanguagesFilter(surveys, observations),
    showObserversFilter: isShowObserversFilter(surveys, observations),
  };
};
